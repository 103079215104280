import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentCode = makeShortcode("ComponentCode");
const ComponentDocs = makeShortcode("ComponentDocs");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "breadcrumb"
    }}>{`Breadcrumb`}</h2>
    <ComponentCode name="Breadcrumb" component="breadcrumb" variation="breadcrumb" hasReactVersion="breadcrumb--default" hasVueVersion="breadcrumb--default" hasAngularVersion="?path=//story/breadcrumb--basic" codepen="yreLJv" mdxType="ComponentCode"></ComponentCode>
    <h2 {...{
      "id": "breadcrumb-with-current-page"
    }}>{`Breadcrumb with current page`}</h2>
    <ComponentCode name="Breadcrumb with current page" component="breadcrumb" variation="breadcrumb--current-page" hasReactVersion="breadcrumb--default" hasAngularVersion="?path=/story/breadcrumb--model" codepen="LvGYxK" mdxType="ComponentCode"></ComponentCode>
    <h2 {...{
      "id": "documentation"
    }}>{`Documentation`}</h2>
    <ComponentDocs component="breadcrumb" mdxType="ComponentDocs"></ComponentDocs>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      